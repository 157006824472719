import React from "react";
import parse, { domToReact, attributesToProps } from "html-react-parser";

const parseModaleComponent = (content) => {
  const opt = {
    replace: ({ attribs, children, name }) => {
      // eslint-disable-next-line no-script-url
      if (attribs?.href?.startsWith("javascript:toggle_modale_")) {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=""
              onClick={(event) => {
                event.preventDefault();
                const showModalComponentEvent = new CustomEvent("show-modal-component", {
                  detail: {
                    // eslint-disable-next-line no-script-url
                    modalId: attribs.href.split("javascript:toggle_modale_")[1]?.split("()")[0],
                  },
                });
                document.dispatchEvent(showModalComponentEvent);
              }}
            >
              {domToReact(children, opt)}
            </a>
          </>
        );
      }
      if (name === "img" && !attribs?.alt) {
        return <img {...attributesToProps(attribs)} alt="" />;
      }
    },
  };

  return parse(content, opt);
};

export default parseModaleComponent;
