import React, { useCallback, useState, useMemo } from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const Motivazioni = ({ data }) => {
  const {
    liferayFields: { articleId },
    title,
    sectionTitle,
    coloreSfondo,
  } = data;
  const [collapseMap, setCollapseMap] = useState(
    sectionTitle.reduce((res, item, index) => {
      res[`${articleId}-${index}`] = !!item.content?.accordionOpen?.value?.[0];
      return res;
    }, {})
  );
  const toggleCollapse = useCallback(
    (key) => setCollapseMap((map) => ({ ...map, [key]: !map[key] })),
    []
  );

  const contentValues = useMemo(
    () =>
      sectionTitle?.map((item) =>
        item.content?.content?.value
          ?.replace(/@corrispettivoEnergia/g, item?.content?.prodottoCRM?.corrispettivoEnergia)
          ?.replace(/@quotaFissaLuce/g, item?.content?.prodottoCRM?.quotaFissaLuce)
          ?.replace(/@quotaFissaGas/g, item?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@componentePrezzoFisso/g, item?.content?.prodottoCRM?.componentePrezzoFisso)
          ?.replace(/@quotaVariabileGas/g, item?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@quotaVariabileLuce/g, item?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@corrispettivoF1/g, item?.content?.prodottoCRM?.corrispettivoF1)
          ?.replace(/@corrispettivoF2/g, item?.content?.prodottoCRM?.corrispettivoF2)
          ?.replace(/@corrispettivoF3/g, item?.content?.prodottoCRM?.corrispettivoF3)
          ?.replace(/@dataFineValidita/g, item?.content?.prodottoCRM?.dataFineValidita)
          ?.replace(/@spreadGas/g, item?.content?.prodottoCRM?.spreadGas)
          ?.replace(/@spreadLuce/g, item?.content?.prodottoCRM?.spreadLuce)
          ?.replace(/@consumoLuceLordoPerdite/g, item?.content?.prodottoCRM?.spreadLuceLordoPerdite)
          ?.replace(/@quotaAnnualeLuceFissa/g, item?.content?.prodottoCRM?.quotaFissaLuceAnnuale)
          ?.replace(/@quotaAnnualeGasFissa/g, item?.content?.prodottoCRM?.quotaFissaGasAnnuale)

          ?.replace(/@spreadF1/g, item?.content?.prodottoCRM?.spreadF1)
          ?.replace(/@spreadF2/g, item?.content?.prodottoCRM?.spreadF2)
          ?.replace(/@spreadF3/g, item?.content?.prodottoCRM?.spreadF3)
          ?.replace(
            /@corrispettivoBiorariaF1/g,
            item?.content?.prodottoCRM?.corrispettivoBiorariaF1
          )
          ?.replace(
            /@corrispettivoBiorariaF23/g,
            item?.content?.prodottoCRM?.corrispettivoBiorariaF23
          )
          ?.replace(
            /@quotaCommFissaDomesticoGas/g,
            item?.content?.prodottoCRM?.quotaCommFissaDomesticoGas
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuce/g,
            item?.content?.prodottoCRM?.quotaCommFissaDomesticoLuce
          )
          ?.replace(
            /@quotaCommVariabileDomesticoGas/g,
            item?.content?.prodottoCRM?.quotaCommVariabileDomesticoGas
          )
          ?.replace(
            /@quotaCommVariabileDomesticoLuce/g,
            item?.content?.prodottoCRM?.quotaCommVariabileDomesticoLuce
          )
          ?.replace(/@quotaPvolSmc/g, item?.content?.prodottoCRM?.quotaPvolSmc)
          ?.replace(/@quotaPvolkWh/g, item?.content?.prodottoCRM?.quotaPvolkWh)
          ?.replace(/@quotaPvolF1kWh/g, item?.content?.prodottoCRM?.quotaPvolF1kWh)
          ?.replace(/@quotaPvolF2kWh/g, item?.content?.prodottoCRM?.quotaPvolF2kWh)
          ?.replace(/@quotaPvolF3kWh/g, item?.content?.prodottoCRM?.quotaPvolF3kWh)
          ?.replace(/@quotaAlfakWh/g, item?.content?.prodottoCRM?.quotaAlfakWh)
          ?.replace(/@quotaAlfaSmc/g, item?.content?.prodottoCRM?.quotaAlfaSmc)
          ?.replace(/@quotaAlfaF1kWh/g, item?.content?.prodottoCRM?.quotaAlfaF1kWh)
          ?.replace(/@quotaAlfaF2kWh/g, item?.content?.prodottoCRM?.quotaAlfaF2kWh)
          ?.replace(/@quotaAlfaF3kWh/g, item?.content?.prodottoCRM?.quotaAlfaF3kWh)
      ),
    [sectionTitle]
  );
  return (
    <section className="section motivazioni pt-4" style={{ backgroundColor: coloreSfondo?.value }}>
      <div className="wrapper-container">
        <div className="container-fluid">
          {title?.value && (
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="section__title" style={{ color: title?.content?.colorTitle?.value }}>
                  {title?.value}
                </h2>
              </div>
            </div>
          )}
          {sectionTitle.length ? (
            <div className="row">
              <div className="col-12">
                <div className="accordions">
                  <ul className="accordions__list">
                    {sectionTitle.map((item, index) => {
                      return (
                        <li key={index} className="accordions__item">
                          <button
                            className="accordions__title"
                            style={{ color: item.content?.colorSectionTitle?.value }}
                            onClick={() => toggleCollapse(`${articleId}-${index}`)}
                            aria-controls={`${articleId}-${index}`}
                            aria-expanded={collapseMap[`${articleId}-${index}`]}
                          >
                            <span
                              className={`accordions__icon${
                                collapseMap[`${articleId}-${index}`]
                                  ? " accordions__icon--open"
                                  : ""
                              }`}
                            ></span>
                            <span>{item.value}</span>
                          </button>
                          <div
                            id={`${articleId}-${index}`}
                            className={`accordions__content${
                              collapseMap[`${articleId}-${index}`]
                                ? " accordions__content--expanded"
                                : ""
                            }`}
                          >
                            <div dangerouslySetInnerHTML={{ __html: contentValues[index] }}></div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default withPreview(Motivazioni);
export const fragment = graphql`
  fragment MotivazioniFragment on LiferayMotivazioni {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
      content {
        colorTitle {
          value
        }
      }
    }
    sectionTitle {
      value
      content {
        prodottoCRM {
          dataFineValidita
          corrispettivoEnergia
          corrispettivoF1
          corrispettivoF2
          corrispettivoF3
          quotaFissaLuce
          quotaFissaGas
          componentePrezzoFisso
          quotaVariabileGas
          quotaVariabileLuce
          spreadGas
          spreadLuce
          spreadLuceLordoPerdite
          quotaFissaLuceAnnuale
          quotaFissaGasAnnuale
          spreadF1
          spreadF2
          spreadF3
          corrispettivoBiorariaF1
          corrispettivoBiorariaF23
          quotaCommFissaDomesticoGas
          quotaCommFissaDomesticoLuce
          quotaCommVariabileDomesticoGas
          quotaCommVariabileDomesticoLuce
          quotaPvolSmc
          quotaPvolkWh
          quotaPvolF1kWh
          quotaPvolF2kWh
          quotaPvolF3kWh
          quotaAlfakWh
          quotaAlfaSmc
          quotaAlfaF1kWh
          quotaAlfaF2kWh
          quotaAlfaF3kWh
        }
        colorSectionTitle {
          value
        }
        content {
          value
        }
        accordionOpen {
          value
        }
      }
    }
    coloreSfondo {
      value
    }
  }
`;
